<template>
  <div class="divide-wrapper">
    <header-common/>

    <main class="monetization-layout">
      <router-view/>
    </main>
    <footer-component
      :cooperation="cooperation"
      :documents="documents"
      :commonLinks="commonLinks"
    />
  </div>
</template>

<script>
import HeaderCommon from '@/components/common/Header';
import FooterComponent from '@/components/common/FooterComponent';

import { mapActions } from 'vuex';
export default {
  name: 'monetization-layout',
  components: {
    HeaderCommon,
    FooterComponent,
  },
  data(){
    return{
      // for FooterComponent
      documents: [
        {
          title: "Пользовательское соглашение",
          link: "/user-agreement.pdf",
          target: '_blank',
        },
        {
          title: "Обработка персональных данных",
          link: "/personal-data.pdf",
          target: '_blank',
        },
        {
          title: "Договор-оферта",
          link: "/oferta.pdf",
          target: '_blank',
        }
      ],
      commonLinks: [
      {
          title: "Партнёрам",
          link: "/cooperation/partners"
        },
        {
          title: "Издательствам",
          link: "/cooperation/publishers"
        },
        {
          title: "Правообладателям",
          link: "/cooperation/rightholders"
        },
        {
          title: "Связь с модератором",
          link: "/cooperation/moderator"
        }
      ],
      cooperation: [
        {
          title: "Школам",
          link: "/cooperation/schools"
        },
        {
          title: "Библиотекам",
          link: "/cooperation/libraries"
        },
        {
          title: "Самозанятым",
          link: "/cooperation/selfEmployed"
        },
        {
          title: "Юридическим лицам",
          link: "/cooperation/organizations"
        },
        {
          title: "Индивидуальным предпринимателям",
          link: "/cooperation/entrepreneurs"
        }
      ],
    }
  },
  methods:{

    ...mapActions({
      fetchGrades: 'gradeData/fetchGrades',
      fetchUser: 'userData/fetchUser',
    }),
    async getUser() {
      try {
        await this.fetchUser();
      }
      catch(err) {
        console.log('Error in getUser:', err);
      }
    },
  },
  async created() {
    await this.getUser();
  },
}
</script>

<style scoped>
.divide-wrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 1272px;
  margin: 0 auto;
}
.monetization-layout{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>