<template>
  <card-background class="content-wrapper">
    <kaz-loader 
      v-if="getNodesLoadingState === LOADING_STATES.LOADING || getNodesLoadingState === LOADING_STATES.INIT" 
      size="L"
    />
    <template v-else-if="getNodesLoadingState === LOADING_STATES.LOADED">
      <kaz-breadcrumbs :links="getBreadcrumbsLinks" :state="getOverviewLoadingState" />
      <div class="section__title">
        <h2 class="heading heading_size_h2">Содержание</h2>
        <input-search
          :value="inputData"
          @update:value="inputData = $event"
          :placeholder="'Поиск по заданиям'"
          :size="'S'"
        />
      </div>
      <KazNotification
        appearance="info"
        label="Заполняй решения к отмеченным заданиям и зарабатывай реальные деньги!"
        description=""
        :visibility="true"
        :closeNeeded="false"
      />
      <content-tree class="content-tree">
        <content-node
          v-for="content in createNodesTree"
          :node="content"
          :isOpen="true"
          :inputData="inputData"
          :nodeRouteName="getDestRouteName"
        />
      </content-tree>
    </template>
    <p
      v-else
      class="body body_size_L"
      style="text-align: center;"
    >
      Что-то пошло не так. Попробуйте перезагрузить страницу.
    </p>
  </card-background>

  <LinkedBooks
    v-show="showLinkedBooks"
    :bookId="bookId" 
    @has-books="payload => showLinkedBooks = payload"
  />

  <!-- <related-lessons
    :bookId="bookId"
    :nodeId="null"
    :srcLessons="prepareRelatedLessons"
  /> -->

  <!-- recommendation section -->
  <!-- <recommendations-container :title="'Дополнительные материалы'">
    <swiper
      :space-between="24"
      :modules="modules"
      :navigation="true"
    >
      <swiper-slide
        v-for="recommendationBook in recommendationBooks"
        :key="recommendationBook.id"
      >
        <book-card
          :id="recommendationBook.id"
          :bookTitle="recommendationBook.bookTitle"
          :bookImg="recommendationBook.bookImg"
          :bookDescription="recommendationBook.bookDescription"
          :authors="recommendationBook.authors"
        />
      </swiper-slide>
    </swiper>
  </recommendations-container> -->

</template>

<script>
import { defineAsyncComponent } from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import CardBackground from '@/components/UI/card/CardBackground.vue';
import KazBreadcrumbs from '@/components/KazUI/molecules/breadcrumbs';
import InputSearch from '@/components/KazUI/atoms/inputs/search';
import RelatedLessons from '@/views/tasks/components/RelatedLessons.vue';
import KazLoader from '@/components/KazUI/atoms/loader';
import KazNotification from '@/components/KazUI/molecules/notification';
import KazLink from '@/components/KazUI/atoms/linkElement';
import LinkedBooks from '@/views/generation/components/LinkedBooks.vue';

import useLoadingStates from '@/hooks/loading/useLoadingStates.js';
import useEditorIntro from '@/hooks/routes/useEditorIntro.js';
import { guessLevelId } from '@/views/tasks/utils/breadcrumbs.js';
import { createMetadataWithToken } from '@/services/utils';
import { EntitiesServiceClient } from '@/generated/entities/entities_service_grpc_web_pb.js';
import { fetchStreamData } from '@/utils/loaders';


const SERVICE_URL = process.env.VUE_APP_REGISTRATION_SERVICE_URL;
const entitiesService = new EntitiesServiceClient(SERVICE_URL, null, null);


export default {
  name: 'book-content',

  components: {
    CardBackground,
    KazBreadcrumbs,
    InputSearch,
    RelatedLessons,
    KazLoader,
    KazNotification,
    KazLink,
    LinkedBooks,
    ContentTree: defineAsyncComponent({
      loader: () => import('@/components/UI/ContentTree')
    }),
    ContentNode: defineAsyncComponent({
      loader: () => import('@/components/UI/ContentNode')
    }),
  },

  setup(props) {
    const { LOADING_STATES } = useLoadingStates();
    const {getDestRouteName} = useEditorIntro('task');
    return {
      LOADING_STATES,
      getDestRouteName,
    }
  },

  data() {
    return {
      recommendationBooks: [],
      inputData: '',
      fetchState: this.LOADING_STATES.INIT,
      bookId: '',
      showLinkedBooks: false,
    }
  },

  watch: {
    '$route.params.bid': {
      handler(newId) {
        if (newId) {
          this.bookId = newId;
        }
      },
      immediate: true
    },
    getBookOverview: {
      async handler(newOverview) {
        if (newOverview) {
          try {
            await this.loadLessons(newOverview.subjectId, newOverview.levelsList);
          } catch (error) {
            console.warn(error);
          }
        }
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters({
      getGrades: 'gradeData/getGrades',
      getCourses: 'courseData/getCourses',
      getGradeState: 'gradeData/getState',
      getCourseState: 'courseData/getState',
      getRelatedLessons: 'relatedLessons/getLessons',
      createNodesTree: 'bookContent/createNodesTree',
      getNodesLoadingState: 'bookContent/getNodesLoadingState',
      getBookOverview: 'bookContent/getOverview',
      getOverviewLoadingState: 'bookContent/getOverviewLoadingState',
      getIdsToSubjects: 'coursesMapping/getIdsToSubjects',
      getIdsToLevels: 'coursesMapping/getIdsToLevels',
    }),
    loadingStates() {
      return [this.getGradeState, this.getCourseState, this.getOverviewLoadingState];
    },
    getBreadcrumbsLinks() {
      const homeLink = { path: { name: 'tasks' }, title: 'Решения' };
      const links = [ homeLink ];
      if (this.isLoadReady(this.loadingStates)) {
        const lvlId = guessLevelId(
          this.getBookOverview.levelsList, 
          this.getBookOverview.name
        );
        const subjId = this.getBookOverview.subjectId;
        // Level link
        if (lvlId) {
          const lvlTitle = this.getGrades.find(lvl => lvl.id === lvlId)?.title || 'Неизвестный класс';
          links.push({
            path: { 
              name: 'level-books', 
              params: { level: this.getIdsToLevels[lvlId] } 
            },
            title: lvlTitle
          });
        }
        // Subject link
        if (subjId) {
          const subjTitle = this.getCourses.find(sub => sub.id === +subjId)?.title || 'Неизвестный предмет';
          links.push({
            path: { 
              name: 'subject-books',
              params: { subject: this.getIdsToSubjects[subjId] }
            },
            title: subjTitle
          });
        }
        // Book link
        links.push({
          path: { name: 'book-content', params: { bid: this.bookId } },
          title: this.getBookOverview.nameOfficial
        });
      }
      return links
    },
    prepareRelatedLessons() {
      // define initial lessons state: selected/non-selected
      return Object.values(this.getRelatedLessons || {});
    }
  },

  methods: {
    ...mapActions({
      createRandomBooks: 'bookData/createRandomBooks',
    }),

    ...mapMutations({
      setRelatedLessons: 'relatedLessons/setLessons'
    }),

    isLoadReady(states) {
      let isReady = true;
      for (const state of states) {
        if (state !== this.LOADING_STATES.LOADED) {
          isReady = false;
          break;
        }
      }
      return isReady
    },

    async loadLessons(subjectId, levelsIds, typeId = 125) {
      /**
       * typeId:
       *  125 - chapters for given subject and level
       *  123 - lessons for given subject adn all levels
       */
      console.warn(`Load lessons: subject=${subjectId}, level=${levelsIds}, typeID=${typeId}`);
      if (!subjectId) return
      try {
        const request = new proto.kazatel.entities.EntitiesRequest();
        if (typeId === 125) {
          request.setLevelsList(levelsIds);
        }
        request.setTypesList([typeId]);
        request.setSubjectsList([subjectId]);
        request.setStatusList([1]);
        request.setPageSize(10);
        const metadata = createMetadataWithToken();
        const stream = entitiesService.entities(request, metadata);
        const streamData = await fetchStreamData(stream);
        let programId;
        let programRootNode;
        streamData.forEach(response => {
          programId = response.getId();
          programRootNode = response.toObject()
          // console.log('Lessons Root Node:', programRootNode);
        })
        if (programId) {
          await this.fetchLessonNodes(programId, levelsIds, subjectId);
        } 
        console.warn('Lessons loaded');
      } catch (err) {
        console.error('Loading [Lessons]:', err);
      }
    },

    async fetchLessonNodes(programId, levelsIds, subjectId) {
      try {
        if (!programId) {
          throw new Error(`Lesson node ID is empty: ${programId}`)
        }
        const metadata = createMetadataWithToken();
        const requestNodes = new proto.kazatel.entities.NodesRequest();
        requestNodes.setEntityId(programId);
        requestNodes.setLevelsList(levelsIds);
        const streamNodes = entitiesService.nodes(requestNodes, metadata);

        const lessons = {};
        let firstLessonId = null;
        const streamData = await fetchStreamData(streamNodes);

        streamData.forEach(response => {
          // console.log('GGGGGGGGGGGGG', response.toObject());
          const lessonId = response.getId();
          if (!response.getPrevId()) {
            firstLessonId = lessonId;
          }
          lessons[lessonId] = {
            id: lessonId,
            nextId: response.getNextId(),
            parentId: response.getParentId(),
            title: response.getTitle(),
            content: [],
            type: response.getType(),
            image: response.getViewUrl()
          };
        });

        /*
        // sorting doesn't work now because of prev/next ids are broken
        const sortedLessons = [];
        while (firstLessonId) {
          sortedLessons.push(lessons[firstLessonId]);
          firstLessonId = lessons[firstLessonId].nextId;
        }*/
        this.setRelatedLessons({
          subjectId: subjectId,
          levelId: levelsIds,
          lessons: lessons,
        });
        console.log('Fetch lesson nodes has been done', lessons.value);
      } catch(err) {
        // console.error('Fetch lesson nodes:', err);
        throw err
      } 
    },

    gotoCompetition() {
      window.open('#', '_blank');
    }
  }
}
</script>

<style scoped>
.content-wrapper {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 32px;
  width: 100%;
  min-height: 50vh;
  padding: 1.5rem;
}

.section__title{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 992px) {
  .input__search{
    margin-left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 568px) {
  .section__title{
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
}

.swiper-slide {
  width: 60% !important;
}

@media screen and (min-width: 480px) {
  .swiper-slide {
    width: 40% !important;
  }
}

@media screen and (min-width: 576px) {
  .swiper-slide {
    width: 30% !important;
  }
}

@media screen and (min-width: 992px) {
  .swiper-slide {
    width: 25% !important;
  }
}
</style>
