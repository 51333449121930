!<template>
  <a 
    href="/manual-money.pdf"
    class="main-banner main-banner__back"
    target="_blank"
  >
    <h2 class="heading heading_size_h2">
      Мы платим за оригинальные решения
    </h2>
    <p class="body body_size_S main-banner__description">
      Заполняй решения для отмеченных красным тэгом заданий и зарабатывай «денежки»
    </p>
  </a>
</template>

<script>
import KazButton from '@/components/KazUI/atoms/button';

import { openPublicFile } from '@/utils/links.js';


export default {
  name: 'banner-original-solutions',

  components: {
    KazButton,
  },

  methods: {
    openPublicFile,
  }
}
</script>

<style scoped>
.main-banner {
  display: block;
  padding: 16px;
  background: #FFBBBB;
  border-radius: 8px;
  box-shadow: 4px 0px 16px rgba(74, 74, 74, 0.15);
  text-decoration: none;
  isolation: isolate;
}

.main-banner__back {
  background-image: url('@/assets/KazIllustrations/finance/denezki-logo.png');
  background-repeat: no-repeat;
  background-position: bottom;
}

.main-banner__link {
  margin-top: 16px;
}

.main-banner__description {
  margin-top: 8px;
  padding-bottom: 110px;
}

@media screen and (max-width: 992px) {
  .main-banner__back {
    background-position: right;
  }
  .main-banner__description {
    padding-bottom: 0;
  }
}
</style>
