<template>
  <div class="wrapper">
    <div class="book-info__wrapper">
      <template v-if="getOverviewLoadingState === LOADING_STATES.LOADED">
        <book-overview :book="getBookOverview"/>
      </template>
      <card-background v-else-if="getOverviewLoadingState === LOADING_STATES.ERROR">
        <div
          v-if="handleOverviewLoadingError(getOverviewLoadingError) === 'Данный материал не доступен на платформе'"
          class="error__wrapper"
        >
          <img src="@/assets/images/locked_content.png" class="error__image" />
          <div class="error__text-wrapper">
            <heading-size-h2 textColor="var(--text-black)">
              {{ handleOverviewLoadingError(getOverviewLoadingError) }}
            </heading-size-h2>
            <ParagraphSizeLarge textColor="var(--text-black)">
              Давай попробуем другой найти другой учебник
            </ParagraphSizeLarge>
          </div>
          <button-primary @click="backToMain">Вернуться назад</button-primary>
        </div>
        <heading-size-h2 v-else textColor="var(--text-black)">
          {{ handleOverviewLoadingError(getOverviewLoadingError) }}
        </heading-size-h2>
      </card-background>
    </div>
    <card-background v-if="isEditor">
      <task-editor-instruction />
    </card-background>
  </div>
  <!-- WARNING: Composition API's data is MUTABLE in contrast of usual props -->
  <router-view/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { defineAsyncComponent, isRef, isReactive } from 'vue';

import CardBackground from '@/components/UI/card/CardBackground.vue';
import LinkHistoryTraversal from '@/components/UI/links/LinkHistoryTraversal.vue';
import ParagraphSizeLarge from '@/components/UI/texts/paragraphs/ParagraphSizeLarge.vue';
import HeadingSizeH2 from '@/components/UI/texts/headings/HeadingSizeH2.vue';
import ButtonPrimary from '@/components/UI/buttons/ButtonPrimary.vue';
import BannerOriginalSolutions from '@/components/common/BannerOriginalSolutions.vue';

import useNodesList from '@/hooks/books/useNodesList.js';
import useLoadingStates from '@/hooks/loading/useLoadingStates.js';
import useBookOverview from '@/hooks/books/useBookOverview.js';
import MetaTagsMixin from '@/utils/mixins/SeoInstallerMixin';


export default {
  name: 'book',

  components: {
    LinkHistoryTraversal,
    ParagraphSizeLarge,
    HeadingSizeH2,
    BannerOriginalSolutions,
    BookOverview: defineAsyncComponent({
        loader: () => import('@/components/cards/BookOverview.vue'),
    }),
    BookPagination: defineAsyncComponent({
        loader: () => import('@/components/UI/BookPagination.vue'),
    }),
    CardBackground,
    TaskEditorInstruction: defineAsyncComponent({
        loader: () => import('@/components/UI/TaskEditorInstruction.vue'),
    }),
    ButtonPrimary
  },

  beforeRouteEnter(to, from) {},

  setup(props, context) {
    const { LOADING_STATES } = useLoadingStates();
    // const { nodesList, isNodesLoading, nodesLoadingError } = useNodesList();
    const { handleOverviewLoadingError } = useBookOverview();
    return { /*nodesList, isNodesLoading, nodesLoadingError,*/ handleOverviewLoadingError, LOADING_STATES };
  },

  mixins: [MetaTagsMixin],

  data() {
    return {};
  },

  watch: {
    '$route': {
      async handler() {
        try {
          const bookId = this.$route.params.bid;
          const result = await Promise.allSettled([this.fetchBookOverview(bookId), this.fetchBookNodes(bookId)]);
          console.log('BOOK OVERVIEW:', result[0]);
          console.log('BOOK NODES:', result[1]);
        } catch (error) {
          console.warn('FETCH BOOK DATA:', error);
        }
      },
      immediate: true
    },
    loadingStates: {
      handler(newStates) {
        let isReady = newStates.overview && newStates.nodes;
        if (isReady) {
          this.setMetaInfo(this.getBookOverview.description);
        }
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters({
      getBookNodes: 'bookContent/getNodes',
      getNodesLoadingState: 'bookContent/getNodesLoadingState',
      getOverview: 'bookContent/getOverview',
      getOverviewLoadingState: 'bookContent/getOverviewLoadingState',
      getOverviewLoadingError: 'bookContent/getOverviewLoadingError',
    }),
    getBookOverview() {
      const neededBook = {
        id: '',
        title: '',
        name: '',
        level: '',
        publisher: '',
        image: '',
        authors: [],
        premium: false,
        year: '',
        type: '',
        parts: [],
        FGOS: false,
        studyDepth: '',
        description: '',
        rank: 0,
      };
      if (this.getOverviewLoadingState === this.LOADING_STATES.LOADED) {
        const srcOverview = this.getOverview;
        neededBook.id = srcOverview.id;
        neededBook.name = srcOverview.name;
        neededBook.title = srcOverview.nameOfficial;
        neededBook.subject = srcOverview.subjectId;
        neededBook.level = srcOverview.levelsList;
        neededBook.publisher = srcOverview.publisher;
        neededBook.image = srcOverview.viewUrl;
        neededBook.authors = srcOverview.authors;
        neededBook.premium = srcOverview.attrPremium;
        neededBook.year = srcOverview.publishYear;
        neededBook.type = srcOverview.type;
        neededBook.parts = srcOverview.part;
        neededBook.FGOS = srcOverview.fgos;
        neededBook.description = srcOverview.description;
        neededBook.rank = srcOverview.rank;
      }
      return neededBook;
    },
    loadingStates() {
      return {
        overview: this.getNodesLoadingState === this.LOADING_STATES.LOADED,
        nodes: this.getOverviewLoadingState === this.LOADING_STATES.LOADED,
      }
    },
    isEditor() {
      return this.$route.name === 'editor';
    }
  },

  methods: {

    ...mapActions({
      fetchBookNodes: 'bookContent/fetchBookNodes',
      fetchBookOverview: 'bookContent/fetchBookOverview',
    }),

    setMetaInfo(customDescription) {
      try {
        // Page title
        const bookTitle = this.getBookOverview.title;
        // const pageTitle = `${this.getBookOverview.name ? this.getBookOverview.name + ' | ' : ''}Казатель Решения`;
        const pageTitle = `${this.getBookOverview.name ? this.getBookOverview.name + ' | ' : ''}Казатель`;
        document.title = pageTitle;
        this.$setOgMeta('og:title', pageTitle);

        // Page description
        let pageDescription;
        if (customDescription) {
          pageDescription = customDescription;
        } else {
          const descriptions = [
            'ГДЗ:',
            this.getBookOverview.type,
            this.getBookOverview.subject,
            this.getBookOverview.authors,
            this.getBookOverview.level.join(', ') + ' класс',
          ];
          pageDescription = descriptions.join(' ');
        }
        this.$setMetaDescription(pageDescription);
        this.$setOgMeta('og:description', pageDescription);
      } catch (error) {
        console.warn('SET META INFO [BOOK]:', error);
      }
    },

    backToMain() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.book-info__wrapper {
  display: flex;
  gap: 16px;
}
.error__wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  text-align: center;
}
.error__text-wrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
@media screen and (max-width: 992px) {
  .book-info__wrapper {
    flex-direction: column;
  }

  .error__image{
    width: 100%;
  }
}
.mock-block {
  width: 100%;
  background-color: white;
  flex-basis: 1;
}
.content-wrapper {
  width: 100%;
  min-height: 50vh;
  align-self: flex-start;
}
.nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.swiper-slide {
  width: 60% !important;
}
@media screen and (min-width: 480px) {
  .swiper-slide {
    width: 40% !important;
  }
}
@media screen and (min-width: 576px) {
  .swiper-slide {
    width: 30% !important;
  }
}
@media screen and (min-width: 992px) {
  .swiper-slide {
    width: 25% !important;
  }
}
</style>
