import { setContentMeta } from '@/utils/meta';


export default {
  install(app, options) {
    app.config.globalProperties.$setMetaDescription = description => setContentMeta('name', 'description', description);
    
    app.config.globalProperties.$setOgMeta = (ogType, content) => setContentMeta('property', ogType, content);
    
    app.config.globalProperties.$setCanonicalUrl = url => {
      const canonicalElement = document.querySelector('link[rel="canonical"]');
      if (!canonicalElement) {
        canonicalElement = document.createElement('link');
        canonicalElement.setAttribute('rel', 'canonical');
        document.head.appendChild(canonicalElement);
      }
      canonicalElement.setAttribute('href', url);
    };

    app.config.globalProperties.$removeCanonicalUrl = () => {
      const canonicalElement = document.querySelector('link[rel="canonical"]');
      if (canonicalElement) {
        canonicalElement.parentNode.removeChild(canonicalElement);
      }
    }
  }
}
