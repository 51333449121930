<template>
  <section class="footer__content-section">
    <heading-size-h3 :textColor="'var(--white)'">{{ sectionTitle }}</heading-size-h3>
    <ul class="content-section__list" :class="classList">
      <li class="content-section__point" v-for="item in getItems">
        <a class="link" :href="item.link" :target="item?.target || '_self'">
          <paragraph-size-medium :textColor="'var(--white)'">
            {{ item.title }}
          </paragraph-size-medium>
        </a>
      </li>
    </ul>
    <button
      v-if="maxVisible"
      class="content-section__button"
      @click="isHidden = !isHidden"
    >
      {{ isHidden ? 'Показать всех партнёров' : 'Скрыть список' }}
      <span>
        <svg-icon  
          :iconName="isHidden ? 'kuiIconChevronDown' : 'kuiIconChevronUp'" 
          size="16px" 
        />
      </span>
    </button>
  </section>
</template>

<script>
import HeadingSizeH3 from '@/components/UI/texts/headings/HeadingSizeH3.vue';
import ParagraphSizeMedium from '@/components/UI/texts/paragraphs/ParagraphSizeMedium.vue';

export default{
  name: "footer-content-section",
  components: {
    HeadingSizeH3,
    ParagraphSizeMedium
  },

  props: {
    sectionTitle: {
      type: String,
      default: "Классы"
    },
    items: {
      type: Array,
      required: false
    },
    listClass: {
      type: String,
      default: ""
    },
    maxVisible: {
      type: [Number, null],
      default: null
    }
  },

  data() {
    return {
      isHidden: true, 
    }
  },

  computed: {
    classList() {
      return ["content__list", this.listClass].join(" ").trim();
    },
    getItems() {
      let items = this.items;
      if (this.maxVisible !== null && this.isHidden) {
        items = this.items.slice(0, Math.min(this.maxVisible, this.items.length));
      }
      return items;
    }
  }
};
</script>

<style scoped>
.footer__content-section{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.content-section__list{
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.content-section__list_type_row{
  flex-direction: row;
  gap: 24px;
}
.link{
  text-decoration: none;
}
.content-section__button {
  background: transparent;
  border: none;
  color: white;
  text-align: start;
  padding: 0;
  --kaz-base-base-09: var(--kaz-textIcons-day-text-01);
}

@media screen and (max-width:768px) {
  .content-section__list{
    gap: 12px;
  }
  .content-section__list_type_row{
    flex-direction: column;
    gap: 16px;
  }
}
</style>